import React, { Fragment } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Breadcrumbs } from "../../components/BreadCrumbs";
import Header from "../../components/Header";
import { AlertModal } from "../../components/Modal";
import SideBar from "../../components/SideBar";
import LoginScreen from "../../containers/Authentication";
import {
  resetFailedRequestProps, signOutAction, refreshToken
} from "../../containers/Authentication/redux/actions";
import CallbackScreen from "../../containers/Callback";
import DefaultScreen from "../../containers/Default";
import ForgotPasswordScreen from "../../containers/ForgotPassword";
import LetsSetupYourAccountScreen from "../../containers/LetsSetupYourAccount";
import { stopLoading } from "../../globalRedux/actions";
import RoutesList from "./Routes";
import FullImage from "../../containers/UntHldDetail/FullImage";
import "./style.scss";
import { OktaAuth } from "@okta/okta-auth-js";
import Loader from "../Loader";
import { CircularProgress } from "@material-ui/core";
import { aConfigUrl } from "../../utils/ConfigUrl";

export class Routes extends React.Component {
  constructor(props) {
    super(props);
    if (window.performance && performance.navigation.type === 1) {
      this.props.stopLoading();
      this.props.resetFailedRequestProps();
    }
  }

  state = {
    auth: false,
    Expire: false,
    isWaitingForRefresh: false,
    autoSignout: false,
    isLoading: false,
    sessionExists: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.autoSignout) {
      return {
        auth: false,
      };
    }
    if (props.auth.accessToken && !props.globalProps.loadingReducer.isLoading) {
      return {
        auth: true,
      };
    }
  
    return null;
  }
  

  static defaultProps = {
    auth: "",
    location: {},
  };

  static propTypes = {
    auth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    location: PropTypes.object,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.intervalId = setInterval(this.CheckActiveTimeout, 10000); 
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  CheckActiveTimeout = async () => {
    const { OKTAExpireUTC } = this.props;
    let authClient = new OktaAuth(aConfigUrl());
    const sessionExists = await authClient.session.exists(); 
    if (sessionExists === false && this.props.location.pathname !== "/login") {
      console.log("Auto signing-out token undefined clause");
      this.setState({ autoSignout: true, auth: false }, () => {
        this.handleSignOut();
      });
    }
    if (this.state.isWaitingForRefresh) {
      console.log("Waiting for refresh");
    } else {
      let dNowTH = new Date();
      let dExpTH = new Date(OKTAExpireUTC);
      let dExpLimit = new Date(OKTAExpireUTC);

      dNowTH.setHours(dNowTH.getHours() + 0);
      dExpTH.setHours(dExpTH.getHours() + 0);
      dExpLimit.setHours(dExpLimit.getHours() + 8);

      
      let dNowPlus2Min = new Date(dNowTH.getTime() + 1 * 60000); 
      if (dNowPlus2Min >= dExpTH) {
        this.setState({ Expire: true })
      }
      if (dNowPlus2Min >= dExpLimit) {
        console.log("Auto signing-out time clause");
        this.setState({ autoSignout: true, auth: false }, () => {
          this.handleSignOut();
        });
      }
    }
  };

  handleSignOut = async () => {
    try {
      this.setState({
        auth: false,
        Expire: false,
        isWaitingForRefresh: false,
        autoSignout: false,
        isLoading: false, // Reset isLoading state
      });
      await this.props.signOutAction();
      this.props.history.push("/login");
    } catch (error) {
      console.error("Error during sign out:", error);
      this.setState({
        auth: false,
        Expire: false,
        isWaitingForRefresh: false,
        autoSignout: false,
        isLoading: false, // Reset isLoading state
      });
      this.props.history.push("/login");
    }
  };
  

  handleExtend = async () => {
    try {
      this.setState({ Expire: false, isWaitingForRefresh: true, isLoading: true });
      await this.props.refreshToken();
      
      await new Promise(resolve => setTimeout(resolve, 6000));
      
      this.setState({ auth: true, isWaitingForRefresh: false, isLoading: false });
      console.log("Refreshed");
    } catch (error) {
      console.error("Failed to refresh token:", error);
      this.handleSignOut();
    }
  };

  renderRouts = () => {
    return RoutesList.map(({ exact, path, component }, i) => {
      return (
        <Fragment key={`Route-${i + 1}`}>
          <Route
            exact={exact}
            path={path}
            component={component}
            key={`Route-${i + 1}`}
          />
          {this.state.auth && 
          this.props.location.pathname === "/login" && 
          (
            <Redirect to="/" />
          )}
          
        </Fragment>
      );
    });
  };

  renderLogin = () => {
    const LoginFormComponent = () => <LoginScreen />;
    const ForgotPasswordComponent = () => <ForgotPasswordScreen />;
    const LetsSetupYourAccountComponent = () => <LetsSetupYourAccountScreen />;
    return (
      <Fragment>
        <Route
          path="/login"
          name="LoginScreen"
          component={LoginFormComponent}
          exact={false}
        />
        <Route
          path="/forgotpassword"
          name="ForgotPasswordScreen"
          component={ForgotPasswordComponent}
          exact={false}
        />
        <Route
          path="/resetpassword"
          name="LetsSetupYourAccountScreen"
          component={LetsSetupYourAccountComponent}
          exact={false}
        />
        <Route
          path="/"
          name="DefaultScreen"
          component={DefaultScreen}
          exact={false}
        />
        <Route
          path="/authService/callback"
          name="CallbackScreen"
          component={CallbackScreen}
          exact={false}
        />
        <Route
          path="/FullDocImage"
          name="FullDocScreen"
          component={DefaultScreen}
          exact={false}
        />
      </Fragment>
    );
  };

  render() {
    const { selectedObject, OKTAExpireUTC, location } = this.props;

    return (
      <Fragment>
        {this.state.auth ? (
          <>
            {this.state.Expire && (
              <AlertModal
                show={this.state.Expire}
                onHide={() => {
                  this.setState({ Expire: false, isWaitingForRefresh: false });
                  this.handleSignOut();
                }}
                onHandleOK={() => {
                  this.setState({ Expire: false, isWaitingForRefresh: false });
                  this.handleSignOut();
                }}
                onHandleExtend={this.handleExtend}
                type="Disapprove"
                status="1"
                title={`Session expired at ${new Date(OKTAExpireUTC).toLocaleString()}`}
                showExtendButton={true}
                okButtonText="Sign Out"
                extendButtonText={"Extend Session"}
              />
            )}
            {location.pathname === "/FullDocImage" ? (
              <FullImage />
            ) : (
              <div className={`wrapComponent`}>
                <SideBar />
                <div className="tableWrap">
                  <Header signOut={this.handleSignOut} />
                  <div className="tableContainer">
                    <Breadcrumbs selectedObject={selectedObject} />
                    {this.state.isLoading && (
                      <div className="regisDevice-list-progress">
                      <CircularProgress />
                      <p className="regisDevice-list-progress-text">
                        {this.state.isLoading ? "Refreshing Token" : null}
                      </p>
                    </div>
                    )}
                    {this.renderRouts()}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          this.renderLogin()
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    clients: state.clientReducer,
    selectedObject: state.breadCrumbsReducer,
    globalProps: state.globalReducer,
  };
};

const mapDispatchToProps = {
  signOutAction,
  stopLoading,
  resetFailedRequestProps,
  refreshToken,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(Routes);