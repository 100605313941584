export const list_of_columns = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    textAlign:"left",
    paddingLeft:10,
    width: 60
  },
  {
    Header: "USERNAME",
    accessor: "username",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "FIRST NAME (EN)",
    accessor: "first_name",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:20
  },
  {
    Header: "LAST NAME (EN)",
    accessor: "last_name",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "FIRST NAME (TH)",
    accessor: "first_name_th",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "LAST NAME (TH)",
    accessor: "last_name_th",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "CONTACT NO.",
    accessor: "mobile",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "CUSTOMER STATUS",
    accessor: "customer_status",
    disableSortBy: true,
    textAlign:"left",
    width: 100,
    paddingLeft:0
  },
  {
    Header: "REGISTER DATE",
    accessor: "created_at",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "SUBMISSION DATE",
    accessor: "submission_at",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "ENHANCED KYC",
    accessor: "enhanced_kyc",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "LAST REVIEW DATE",
    accessor: "review_at",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "EMAIL",
    accessor: "email",
    disableSortBy: true,
    textAlign:"left",
    width: 280,
    paddingLeft:0
  },
  {
    Header: "RISK LEVEL",
    accessor: "risk_level",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:20
  },
  {
    Header: "UNITHOLDER ID",
    accessor: "unitholder_id",
    disableSortBy: true,
    textAlign:"left",
    width: 120,
    paddingLeft:0
  },
  {
    Header: "CONVERTED USER",
    accessor: "has_converted",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "DOC. STATUS",
    accessor: "document_status",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
    Header: "ACC. STATUS",
    accessor: "status_code",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:0
  },
  {
   Header: "ACTION",
   accessor: "action",
   textAlign:"left",
   width: 110,
   paddingLeft:0
  } 
];