import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  requestSaveAndPublishContentAction,
  receiveSaveAndPublishContentAction,
  failSaveAndPublishContentAction,
  requestFeedContentAction,
  receiveFeedContentAction,
  failFeedContentAction,
  requestDeleteFeedContentAction,
  receiveDeleteFeedContentAction,
  failDeleteFeedContentAction,
  // -----ArticleTag------
  REQArticleTag,
  RESArticleTag,
  FailArticleTag,
  // -----Fund------
  REQFund,
  RESFund,
  FailFund,
  REQFundTag,
  RESFundTag,
  FailFundTag,
  // ----Edit ContentFeedDetail----
  REQContFdEdt,
  FailContFdEdt,
  // ----Creatt ContentFeedDetail----
  REQContFdCrt,
  FailContFdCrt,
  // ----Edit ContentFeed Noti Invest Detail----
  REQContFdNotiInvestEdt,
  RESContFdNotiInvestEdt,
  FailContFdNotiInvestEdt,
  // ----Creat ContentFeed Noti Invest Detail----
  REQContFdNotiInvestCrt,
  RESContFdNotiInvestCrt,
  FailContFdNotiInvestCrt,
  // ----Delete ContentFeedDetail----
  REQContFdDel,
  RESContFdDel,
  FailContFdDel,
  // ----Delete ContentFeed Noti Invest Detail----
  REQContFdNotiInvestDel,
  RESContFdNotiInvestDel,
  FailContFdNotiInvestDel,
  // fund code
  REQFundCodeList,
  RESFundCodeList,
  FailFundCodeList,
  
} from "./actions";
import * as actions from "../../Authentication/redux/actions";
import { v4 as UUIDv4 } from "uuid";

function* fetchFeedContent() {
  try {
    const { feedUUID } = yield select(state => state.conFdDetailReducer);
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds/${feedUUID}?fields=interestCode%2CstatusCode%2Ccontent%7Ben%2Cth%7D%2CcoverImgUrl%7Ball%7D%2CfullContentUrl%7Ben%2Cth%7D%2CarticleTitle%7Ben%2Cth%7D%2CurlTitle%7Ben%2Cth%7D`,
      {
        headers:
        {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
       },
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  
    if (response && response.data && response.data.data) {
      yield put(receiveFeedContentAction(response.data.data));
    } else {
      yield put(failFeedContentAction());
    }
  } catch (error) {
    
    yield put(failFeedContentAction());
  }
}
function* putFeedContent({ payload }) {
  try {
    const { formData, history } = payload;
    
    const { feedUUID } = yield select(state => state.conFdDetailReducer);
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let uuid;
    let body;
    if (feedUUID) {
      // This article already exists, we are overwriting it with new info.
      uuid = feedUUID;

      body = {
        interestCode: formData["categoryDropdown-en"],
        statusCode: "2",
        content: {
          en: formData["theContent-en"],
          th: formData["theContent-th"]
        },
        coverImgUrl: formData["coverPhoto"],
        fullContentUrl: {
          en: formData["fullContentURL-en"],
          th: formData["fullContentURL-th"]
        },
        articleTitle: {
          en: formData["articleTitle-en"],
          th: formData["articleTitle-th"]
        },
        urlTitle: {
          en: formData["titleOfURL-en"],
          th: formData["titleOfURL-th"]
        }
      };
    } else {
      // FrontEnd generates a unique UUIDv4 every time we create a feed article.
      uuid = UUIDv4();
      body = {
        interestCode: formData["categoryDropdown-en"],
        statusCode: "2",
        content: {
          en: formData["theContent-en"],
          th: formData["theContent-th"]
        },
        coverImgUrl: formData["coverPhoto"],
        fullContentUrl: {
          en: formData["fullContentURL-en"],
          th: formData["fullContentURL-th"]
        },
        articleTitle: {
          en: formData["articleTitle-en"],
          th: formData["articleTitle-th"]
        },
        urlTitle: {
          en: formData["titleOfURL-en"],
          th: formData["titleOfURL-th"]
        }
      };
    }

    const response = yield call(
      api.put,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds/${uuid}`,
      body,
      {
        headers:
        {
          "Content-Type": "application/json",
          userid: tGUID,
            id_token: "Bearer " + accessToken
        },
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  

    if (response && response.data && response.status === 200) {
      yield put(receiveSaveAndPublishContentAction());
      history.goBack();
    } else {
      yield put(failSaveAndPublishContentAction());
    }
  } catch (error) {
    
    yield put(failSaveAndPublishContentAction());
  }
}
function* deleteFeedContent({ payload }) {
  try {
    const { history } = payload;
    const { feedUUID } = yield select(state => state.conFdDetailReducer);
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.del,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds/${feedUUID}`,
      {
        headers:
        {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
       },
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  

    if (response && response.data && response.status === 200) {
      yield put(receiveDeleteFeedContentAction());
      history.goBack();
    } else {
      yield put(failDeleteFeedContentAction());
    }
  } catch (error) {
    
    yield put(failDeleteFeedContentAction());
  }
}
// ----------------GETArticleTag -------------
function* fetchArticleTagListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/tag/article_tag`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  

    if (response && response.data) {
      yield put(RESArticleTag(response.data));
    } else {
            yield put(FailArticleTag());
    }
  } catch (error) {
    
    yield put(FailArticleTag());
  }
}
// ----------------GetFund -------------
function* fetchFundListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { page, searchValue } = payload;

    const request = yield fetch(
      // direct
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds?page=${page}&search=${searchValue}`,
      {
        method: "GET",  
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  
    const response = yield request.json();

    if (response) {
      yield put(RESFund(response));
    } else {
      yield put(FailFund());
    }
  } catch (error) {
    
    yield put(FailFund());
  }
}


// ----------------Edit ContentFeedDetail -------------
function* EDTxContFdDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    console.log("EDTxContFdDetail:",body);
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds/article/edit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body:JSON.stringify(body)
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  
    const response = yield request.json();
    console.log("response:",response);
    if (response && response.message === 'success') {
      history.goBack();
    } else {
      yield put(FailContFdEdt());
    }
  } catch (error) {
    console.error("error:",error);
    yield put(FailContFdEdt());
  }
}
// ----------------Creat ContentFeedDetail -------------
function* CRTxContFdDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    console.log("CRTxContFdDetail body:",body);
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds/article/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body:JSON.stringify(body)
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  
    const response = yield request.json();
    console.log("response:",response);
    if (response && response.message === 'success') {
      history.goBack();
    } else {
      yield put(FailContFdCrt());
    }
  } catch (error) {
    console.error("error:",error);
    yield put(FailContFdCrt());
  }
}
// ----------------Del ContentFeedDetail -------------
function* DELxContFdDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    console.log("DELxContFdDetail:",body);
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds/article/delete`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body:JSON.stringify(body)
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  
    const response = yield request.json();
    console.log("response:",response);
    if (response && response.message === 'success') {
      yield put(RESContFdDel(response));
      history.goBack();
    } else {
      yield put(RESContFdDel());
    }
  } catch (error) {
    console.error("error:",error);
    yield put(FailContFdDel());
  }
}
// ----------------Edit ContentFeed Noti Invest Detail -------------
function* EDTxContFdNotiInvestDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds/article/edit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body:JSON.stringify(body)
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  
    const response = yield request.json();
    console.log("response:",response);
    if (response && response.status === 'success') {
      yield put(RESContFdNotiInvestEdt(response));
      history.goBack();
    } else {
      yield put(FailContFdNotiInvestEdt());
    }
  } catch (error) {
    
    yield put(FailContFdNotiInvestEdt());
  }
}
// ----------------Edit ContentFeed Noti Invest Detail -------------
function* CRTxContFdNotiInvestDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds/article/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body:JSON.stringify(body)
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  
    const response = yield request.json();
    console.log("response:",response);
    if (response && response.message === 'success') {
      yield put(RESContFdNotiInvestCrt(response));
      history.goBack();
    } else {
      yield put(FailContFdNotiInvestCrt());
    }
  } catch (error) {
    console.error("error:",error);
    yield put(FailContFdNotiInvestEdt());
  }
}
// ----------------Edit ContentFeed Noti Invest Detail -------------
function* DELxContFdNotiInvestDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds/article/delete`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body:JSON.stringify(body)
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  
    const response = yield request.json();
    console.log("response:",response);
    if (response && response.message === 'success') {
      yield put(RESContFdNotiInvestDel(response));
      history.goBack();
    } else {
      yield put(FailContFdNotiInvestDel());
    }
  } catch (error) {
    console.error("error:",error);
    yield put(FailContFdNotiInvestDel());
  }
}
function* fetchFundCodeListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);

  try {
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );

    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  

    
    if (response && response.data) {
      yield put(RESFundCodeList(response.data));
    } else {
            yield put(FailFundCodeList());
    }
  } catch (error) {
    
    yield put(FailFundCodeList());
  }
}

export function* conFdDetailSaga() {
  yield all([
    takeLatest(requestSaveAndPublishContentAction, putFeedContent),
    takeLatest(requestFeedContentAction, fetchFeedContent),
    takeLatest(requestDeleteFeedContentAction, deleteFeedContent),
    // -----ArticleTag----
    takeLatest(REQArticleTag, fetchArticleTagListWorker),
    // -----Tag----
    takeLatest(REQFund, fetchFundListWorker),
    // -----Edit----
    takeLatest(REQContFdEdt, EDTxContFdDetail),
    takeLatest(REQContFdNotiInvestEdt, EDTxContFdNotiInvestDetail),
    // ------Creat---
    takeLatest(REQContFdCrt, CRTxContFdDetail),
    takeLatest(REQContFdNotiInvestCrt, CRTxContFdNotiInvestDetail),
    // -----Delete----
    takeLatest(REQContFdDel, DELxContFdDetail),
    takeLatest(REQContFdNotiInvestDel, DELxContFdNotiInvestDetail),
    //
    takeLatest(REQFundCodeList, fetchFundCodeListWorker),
  ]);
}
