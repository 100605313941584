import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  REQSrchRecList,
  RESSrchRecList,
  FailSrchRecList,
} from "./actions";
import * as actions from "../../Authentication/redux/actions";


function* fetchSrchRecListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    const request = yield fetch(
      // direct
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/search_recommend`,
      {
        method: "GET",  
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    const response = yield request.json();
    
    console.log("search_recommend response:",response.data);
    if (response && response.data) {
     
      yield put(RESSrchRecList(response.data));
    } else {
      
      yield put(FailSrchRecList());
    }

  } catch (error) {
    
    yield put(FailSrchRecList());
  }
}



export function* srchRecListSaga() {
  yield all([
    takeLatest(REQSrchRecList, fetchSrchRecListWorker)  
  ]);
}
