import { OktaAuth } from "@okta/okta-auth-js";
import React, { Component } from "react";
import "./style.scss";
class CallbackScreen extends Component {

  componentDidMount() {
    this._initScreen();
  }

  _initScreen() {
    let config ;

    if (process.env.REACT_APP_ENV.trim() === "dev_sit") {
      config = {
        issuer: "https://principal-poc.okta.com/oauth2/ausf2c9hxS0KHQmKl3l6",
        clientId: "0oaj5mu94YmngL2t13l6",
        discoveryUri:
          "https://principal-poc.okta.com/oauth2/ausf2c9hxS0KHQmKl3l6",
        pkce: true,
        // Test localhost SIT
        redirectUri: "http://localhost:3000/authService/callback"
      };
    }
    else if (process.env.REACT_APP_ENV.trim() === "dev_uat") {
      config = {
        issuer: "https://principal.oktapreview.com/oauth2/ausx7pbotj5nvGlol0h7",
        clientId: "0oaygjhktbnymeZ3e0h7",
        discoveryUri:
          "https://principal.oktapreview.com/oauth2/ausx7pbotj5nvGlol0h7",
        pkce: true,
        // Test localhost UAT
        redirectUri: "http://localhost:3000/authService/callback"
      };
    }
    else if (process.env.REACT_APP_ENV.trim() === "production") {
      /*real production
      config = {
        issuer: "https://principal-ciam1.okta.com/oauth2/ausjqe9nwgvp5evcg3l6",
        clientId: "0oajqit868r4rniZM3l6",
        discoveryUri:
          "https://principal-ciam1.okta.com/oauth2/ausjqe9nwgvp5evcg3l6",
        pkce: true,
        // PRO
        redirectUri: "https://app.principal.th/authService/callback"
      };
      */
      config = { //Beta Test UAT OKTA
        issuer: "https://principal.oktapreview.com/oauth2/ausx7pbotj5nvGlol0h7",
        clientId: "0oaygjhktbnymeZ3e0h7",
        discoveryUri:
          "https://principal.oktapreview.com/oauth2/ausx7pbotj5nvGlol0h7",
        pkce: true,
        // PRO
        redirectUri: "https://app.principal.th/authService/callback"
      };
    }
    else if (process.env.REACT_APP_ENV.trim() === "uat") {
      config = {
        issuer: "https://principal.oktapreview.com/oauth2/ausx7pbotj5nvGlol0h7",
        clientId: "0oaygjhktbnymeZ3e0h7",
        discoveryUri:
          "https://principal.oktapreview.com/oauth2/ausx7pbotj5nvGlol0h7",
        pkce: true,
        // UAT
        redirectUri:
          "https://app.staging.principal.th/authService/callback"
      };
    }else {
      config = {
        issuer: "https://principal-poc.okta.com/oauth2/ausf2c9hxS0KHQmKl3l6",
        clientId: "0oaj5mu94YmngL2t13l6",
        discoveryUri:
          "https://principal-poc.okta.com/oauth2/ausf2c9hxS0KHQmKl3l6",
        pkce: true,
        // SIT
        redirectUri:
          "https://app.dev.principal.th/authService/callback"
      };
    }
    
    let authClient = new OktaAuth(config);

    authClient.session.exists().then(() => {
    });

    authClient.session.get().then(data => console.log("authClient",data));

    authClient.token
      .getWithoutPrompt({
        responseType: "id_token"
      })
      .then(function(res) {
        var tokens = res.tokens;
        authClient.tokenManager.setTokens(tokens);
      })
      .catch(function(err) {
        console.log('catch authClient.token', err);
      });
  }

  render() {
    return (
      <div className="wrapper">
        <div className="imageBackgroundWrapDefaultPage" />
      </div>
    );
  }
}

export default CallbackScreen;
