import { put, call, all, takeLatest, select } from "redux-saga/effects";
import {
  REQConsentVSList,
  RESConsentVSList,
  FailConsentVSList,
} from "./actions";
import * as actions from "../../Authentication/redux/actions";

function* fetchConsentVSListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { page,nConsentTypeId,tSortH} = payload;

    const request = yield fetch(`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/consentVS?page=${page}`, 
    { 
      method: "POST",  
      headers:
       {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
      },
      body: JSON.stringify({
        consentTypeId:nConsentTypeId,
       sortH:tSortH
      })
       
    });
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
   
    const response = yield request.json();
     
    if (response) 
    {
     yield put(RESConsentVSList(response));
    } 
    else
    {
      yield put(RESConsentVSList());
    }

  } catch (error) {
    yield put(FailConsentVSList());
  }
}

export function* consentVSListSaga() {
  yield all([
    takeLatest(REQConsentVSList, fetchConsentVSListWorker)  
  ]);
}
