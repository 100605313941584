import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  REQConFdSortList,
  RESConFdSortList,
  FailConFdSortList,
  // Edit
  REQConFdSortEdt,
  RESConFdSortEdt,
  FailConFdSortEdt
} from "./actions";
import * as actions from "../../Authentication/redux/actions";

function* fetchConFdSortListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    // eaka
    const result = yield fetch(
     `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/content_feed_sort/list`,
     {
       method: "GET",  
       headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (result.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
  
    const response = yield result.json();

    if (response && response.data) {
     
      yield put(RESConFdSortList(response.data));
    } else {
      
      yield put(FailConFdSortList());
    }

  } catch (error) {
    
    yield put(FailConFdSortList());
  }
}

function* EDTxConFdSort({ payload }) {
  try 
  {
    const { FormData,IdForAPI, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = {
        id:IdForAPI,
        contentFeed:FormData
    }; 
    const response = yield call(
      api.post,
     `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/content_feed_sort/edit`,
      body,
      {
        headers:
        {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
       },
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }

  if (response && response.data && response.status === 200) {
      yield put(RESConFdSortEdt(response.data));
     history.push(`/ContenetFeedSort`); 
    } 
    else 
    {
      yield put(FailConFdSortEdt());
    }
  } catch (error) {
    
    yield put(FailConFdSortEdt());
  }
}

export function* conFdSortListSaga() {
  yield all([
    takeLatest(REQConFdSortList, fetchConFdSortListWorker) ,
    takeLatest(REQConFdSortEdt, EDTxConFdSort)
  ]);
}
