import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Images from "../../utils/styles/images";
import { WarningModal } from "../../components/Modal";
import { selectedObjectUuid } from "../../components/BreadCrumbs/redux/actions";
import {

  MEMConFdEdt,
  setContentFeedModeAction
} from "../ContentFeedDetail/redux/actions";
import TableSubMenu from "./table_ContentFdSubMenu";
import { col_SubMenu, col_SubMenuSrch } from "./list_of_columns";
import {
  requestInterestsListAction,
  REQConFdList,
  REQSyncEN,
  REQSyncTH,
  REQSyncPDFEN,
  REQSyncPDFTH,
  CleanUpConFdList
} from "./redux/actions";
import "./style.scss";
import Pagination from "./Pagination";

function ContentFeedList(props) {
  const {
    // ----Get Contfeed---
    MEMConFdEdt,
    REQStrConFdList,
    REQConFdList,
    ConFdList,
    totalConFd,
    // ---Get Synce---
    REQSyncEN,
    REQSyncTH,
    // ---Get Synce PDF---
    REQSyncPDFEN,
    REQSyncPDFTH,
    history
  } = props;

  // Use Sync
  const [tPara, SETxPara] = useState("");
  // Use Create
  const [tConTypeCrt, SETtConTypeCrt] = useState("");
  // Use Create
  const [tRoute, SETtRoute] = useState("");

  const oCols_SubMenuSrch = React.useMemo(() => col_SubMenuSrch, []);
  const oCols_SubMenu= React.useMemo(() => col_SubMenu, []);

  const [bHighlight, SETbHighlight] = useState(false);
  const [nPageIndex, SETxPageIndex] = useState(0);

  const [searchValue, setSearchValue] = useState(undefined);
  console.log("searchVal List:", searchValue)
  // ---------------Main menu-------------------
  // start test
  const routeMappings = {
    "/ConFd": { 
      fields: "Old", 
      contentType: "Old"
    },
    "/ConFd_NewsOld": {
      fields: "Old",
      contentType: "Old"
    },
    "/ConFd_Invest": { 
      fields: "investment_outlook", 
      contentType: "investment_outlook",
      route: "ConFd_Invest" 
    },
    "/ConFd_News": {
      fields: "news_and_award",
      contentType: "news_and_award",
      route: "ConFd_News"
    },
    //
    "/ConFd_ContentInvest": { 
      fields: "investment_knowledge", 
      contentType: "investment_knowledge",
      route: "ConFd_ContentInvest" 
    },
    "/ConFd_ManualInvest": {
      fields: "investor_guideline",
      contentType: "investor_guideline",
      route: "ConFd_ManualInvest"
    },
    "/ConFd_PVDNewsReport": {
      fields: "Provident Fund News and Report",
      contentType: "Provident Fund News and Report",
      route: "ConFd_PVDNewsReport"
    },
    "/ConFd_PVDPlaning": {
      fields: "Financial Planning Content",
      contentType: "Financial Planning Content",
      route: "ConFd_PVDPlaning"
    },
    "/ConFd_PVDFactSheet": {
      fields: "Provident Fund Fact Sheet",
      contentType: "Provident Fund Fact Sheet",
      route: "ConFd_PVDFactSheet"
    },
    "/ConFd_NotifyInvest": {
      fields: "fund_announcement",
      contentType: "fund_announcement",
      route: "ConFd_NotifyInvest"
    },
    "/ConFd_Srch": {
      fields: "",
      contentType: "",
      route: "ConFd_Srch"
    }
    
  };
  
  const CALLxContentFeed = () => {
    const currentPath = history.location.pathname;
    const mapping = routeMappings[currentPath];
    if (mapping) {
      SETxPara("?" + mapping.fields);
      SETtConTypeCrt(mapping.contentType);
  
      if (mapping.route) {
        SETtRoute(mapping.route);
      }

      const locationPage = history.location.PageCurrent || 1;
      const searchText = searchValue || '';
      REQConFdList({
        // FormData: "?fields=" + mapping.fields + "&page=" + locationPage,
        FormData: `?fields=${mapping.fields}&page=${locationPage}&search=${searchText}&isHighlight=${bHighlight}`,
        history
      });
    } else {
      // Handle the case where there's no mapping
      console.error("No route mapping found for path:", currentPath);
    }
  };
  // end test

  const SETxRecallREQ = () => {
    setTimeout(function() {
      REQConFdList({
        FormData: tPara,
        history
      });
    }, 2000);
  };

  const [oShwSyncAlr, SETxShwSyncAlr] = useState(false);

  // start test
  function handleDetailNavigation(rowData, pageIndex, currentPath) {
    const routeMappings = {
      "/ConFd": "/ConFd_NewsOldDetail",
      "/ConFd_NewsOld": "/ConFd_NewsOldDetail",
      "/ConFd_Invest": "/ConFd_InvestDetail",
      "/ConFd_News": "/ConFd_NewsDetail",
      "/ConFd_ContentInvest": "/ConFd_ContentInvestDetail",
      "/ConFd_ManualInvest": "/ConFd_ManualInvestDetail",
      "/ConFd_PVDNewsReport": "/ConFd_PVDNewsReportDetail",
      "/ConFd_PVDPlaning": "/ConFd_PVDPlaningDetail",
      "/ConFd_PVDFactSheet": "/ConFd_PVDFactSheetDetail",
      "/ConFd_NotifyInvest": "/ConFd_NotifyInvestDetail"
    };
  
    const detailPath = routeMappings[currentPath]; 
  
    if (detailPath) {
      window.addEventListener("popstate", function () {
        history.push({ pathname: currentPath, PageCurrent: pageIndex });
      });
  
      history.push(`${detailPath}/${rowData.id}`);
    } else {
      console.warn("No detail route mapping found for path:", currentPath);
    }
  }

  const contentTypeMappings = {
    "": "/ConFd_SrchFdDetail",
    "investment_outlook": "/ConFd_SrchFdDetail",
    "news_and_award": "/ConFd_SrchFdDetail",
    "investment_knowledge": "/ConFd_SrchFdDetail",
    "investor_guideline": "/ConFd_SrchFdDetail",
    "Provident Fund News and Report": "/ConFd_SrchFdDetail",
    "Financial Planning Content": "/ConFd_SrchFdDetail",
    "Provident Fund Fact Sheet": "/ConFd_SrchFdDetail",
    "media_center": "/ConFd_SrchNotiDetail",
    "fund_announcement": "/ConFd_SrchNotiDetail"
  };

  const SETxAcTableClick = ( rowData, columnName, pageIndex) => {
    if (columnName === "ARTICLE TITLE" || columnName === "VIEW") {
      MEMConFdEdt({ 
        mode: "OLD_CONTENT", 
        RowDataAt: rowData, 
        PageCurrent: pageIndex
      });
  
      if (history.location.pathname === "/ConFd_Srch") {
          const detailRoute = contentTypeMappings[rowData.contentType] || "/ConFd_SrchFdDetail"; 
          history.push(`${detailRoute}/${rowData.id}`);
      } else {
        handleDetailNavigation(rowData, pageIndex, history.location.pathname);
      }
    }
  };
  // end test

  const GETxSyncContent = () => {
    SETxShwSyncAlr(true);
  };
  const SETxChangeHighlight = () => {
    
    bHighlight === false ? SETbHighlight(true) : SETbHighlight(false);
  };
  // fix lint
  // const SHWoOnlyHighlight = () => {
  //   let oFiterHighlight;
  //   if (history.location.pathname === "/ConFd_Srch") {
  //     if (bHighlight === true) {
  //       oFiterHighlight = ConFdList.filter(f => f.isHighlight === true);
  //     } else {
  //       oFiterHighlight = ConFdList;
  //     }
  //   } else {
  //     oFiterHighlight = ConFdList;
  //   }

  //   return oFiterHighlight;
  // };

  const SETxPageGo = pnPageAt => {
    let nPageAt = pnPageAt + 1;
    const currentPath = history.location.pathname;
    const mapping = routeMappings[currentPath];
    if (mapping) {
      SETxPageIndex(pnPageAt);
      const searchText = searchValue || '';
      REQConFdList({
        // FormData: "?fields=" + mapping.fields + "&page=" + locationPage,
        FormData: `?fields=${mapping.fields}&page=${nPageAt}&search=${searchText}&isHighlight=${bHighlight}`,
        history
      });
    } else {
      // Handle the case where there's no mapping
      console.error("No route mapping found for path:", currentPath);
    }
  };

  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalConFd / 10);
    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
    };

  useEffect(() => {
    CALLxContentFeed();

    return () => {
      // cleanup
    }
  }, [searchValue, bHighlight]);

  // start test
  function sync(language, history) {
    const url = "feeds/api/v1/admin/sync";
    if (language === "en") {
      REQSyncEN({ URL: url, Sync: language, history }); 
    } else { 
      REQSyncTH({ URL: url, Sync: language, history }); 
    }
  }

  function syncPDF(language, history) {
    const url = "feeds/api/v1/admin/sync_pdf";
    if (language === "en") {
      REQSyncPDFEN({ URL: url, Sync: language, history }); 
    } else { 
      REQSyncPDFTH({ URL: url, Sync: language, history }); 
    }
  }
  
  function syncMedia(language, history) {
    const url = "feeds/api/v1/admin/sync_media_center";
    if (language === "en") {
      REQSyncPDFEN({ URL: url, Sync: language, history }); 
    } else { 
      REQSyncPDFTH({ URL: url, Sync: language, history }); 
    }
  }
 

  useEffect(() => {
    /*syncAll(history)
      .catch(error => { 
          // Handle any errors in the sync process
          console.error("Error occurred:", error);
      });*/
  }, [history]);
  // end test

  return (
    <Fragment>
      <div className="button-create-container" style={{ marginBottom: 1 }}>
        <Link
          className="button-create-new-content"
          style={{
            visibility:
              history.location.pathname === "/ConFd_Srch" ? "hidden" : ""
          }}
          to={{
            pathname:
              history.location.pathname === "/ConFd_NotifyInvest"
                ? `/ConFdNotiInvestCrt`
                : `/ConFdCrt`,
            ContType: tConTypeCrt,
            Route: tRoute
          }}
          onClick={() => {}}
        >
          CREATE NEW CONTENT
        </Link>

        <button
          type="submit"
          onClick={GETxSyncContent}
          style={{
            visibility:
              history.location.pathname === "/ConFd_Srch" ? "hidden" : ""
          }}
          className={"conFd-synce-button"}
        >
          <img
            src={Images.menuIcon.transaction}
            width={20}
            height={20}
            style={{ marginBottom: 3, marginRight: 5 }}
            alt="SYNC"
          />
          SYNC
        </button>
      </div>
      {REQStrConFdList ? (
        <div className="funds-list-progress">
          <CircularProgress />
          <p className="funds-list-progress-text">
            {REQStrConFdList ? "Retrieving Contentfeed list..." : null}
          </p>
        </div>
      ) : null}
      <WarningModal
        show={oShwSyncAlr}
        onHide={() => SETxShwSyncAlr(false)}
        onHandleGo={() => {
          SETxShwSyncAlr(false);
          // start test
          try {
            if (history.location.pathname === "/ConFd_NotifyInvest") {
              syncPDF("en", history);
              syncPDF("th", history);
              syncMedia("en", history);
              syncMedia("th", history);
              SETxRecallREQ();
              setTimeout(function() {
                window.location.reload();
            }, 2000);
            } else {
              sync("en", history);
              sync("th", history);
              SETxRecallREQ();
              setTimeout(function() {
                  window.location.reload();
              }, 2000);
            }
            // end test
        } catch (ex) {
            console.log('onHandleGo catch', ex);
        }        
        }}
        onHandleCancel={() => {
          SETxShwSyncAlr(false);
        }}
        type={"Disapprove"}
        status={"1"}
        title={
          "Are you sure you want to sync contents from website?"
        }
      />

      <TableSubMenu
        // data={SHWoOnlyHighlight()}
        data={ConFdList}
        ShowOnlyHighlight={bHighlight}
        handleChangeHighlight={SETxChangeHighlight}
        handleSearch={setSearchValue}
        columns={
          history.location.pathname === "/ConFd_Srch"
            ? oCols_SubMenuSrch
            : oCols_SubMenu
        }
        HightTable={'500'}
        handleActionClick={SETxAcTableClick}
        history={history}
        nPageIndex={nPageIndex}
      />
      {REQStrConFdList ? null : <CALxPaging />}
      {/* <CALxPaging /> */}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.contentFeedListReducer
  }),
  {
    requestInterestsListAction,
    selectedObjectUuid,
    setContentFeedModeAction,
    REQConFdList,
    REQSyncEN,
    REQSyncTH,
    REQSyncPDFEN,
    REQSyncPDFTH,
    CleanUpConFdList,
    MEMConFdEdt
  }
);

export default compose(
  withConnect,
  withRouter
)(ContentFeedList);
