import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  requestResetPasswordAction,
  receiveResetPasswordAction,
  failResetPasswordAction
} from "./actions";
import * as actions from "../../Authentication/redux/actions";


function* fetchResetPassword({ payload }) {
  try {
    const { newPassword, resetToken } = payload;
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = {
      password: newPassword,
      userType:"admin",
      token: resetToken,
    };

       const request = yield fetch(
      `${process.env.REACT_APP_API_URL}/thB2C/v2/reset-password/confirm`,
      {
        method: "POST",  
        headers,
        body: JSON.stringify(body),
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    const response = yield request.json();

    if (response && response.error) {
      yield put(
        failResetPasswordAction(response.error[0].message)
      );
    } else {
      yield put(receiveResetPasswordAction());
    }
  } catch (error) {
    
    if (error.response.error.statusCode === 401) {
      yield put(
        failResetPasswordAction(
          "Your access to this service has timed out. Please go through the Forgot Password process again."
        )
      );
    } else {
      yield put(
        failResetPasswordAction("Service was unable to reset password")
      );
    }
  }
}

export function* resetPasswordSagas() {
  yield all([takeLatest(requestResetPasswordAction, fetchResetPassword)]);
}
