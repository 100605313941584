import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {Base64} from 'js-base64';
import {
  // ----Tab2 RegisDevice----
  REQTab2_RegisDeviceList,
  RESTab2_RegisDeviceList,
  FailTab2_RegisDeviceList,
  // --------------Chage Status Detail-----------------
    REQChgSta,
    RESChgSta,
    FailChgSta,
  // ----- Get Image-------
  REQImgCus,
  RESImgCus,
  FailImgCus,
 // ----- Get Sta-------
    REQSta,
    RESSta,
    FailSta,
  // ----Delete UserAccount----
    REQDelUsrAct,
    RESDelUsrAct,
    FailDelUsrAct  
} from "./actions";
import * as actions from "../../Authentication/redux/actions";

function* fetchTab2_RegisDeviceListWorker({ payload }) {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    const  {customerUuid}  = payload;
    const request = yield fetch(`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/registerDeviceList/search`, 
    { 
      method: "PUT",  
      headers: {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
      },
      body: JSON.stringify(
        {
          cusUuid: customerUuid
        })
    });
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    const response = yield request.json();
     
    if (response) 
    {
      yield put(RESTab2_RegisDeviceList(response));
    } 
    else
     {
      
      yield put(FailTab2_RegisDeviceList());
    }

  } catch (error) {
    
    yield put(FailTab2_RegisDeviceList());
  }
}
function* CHGxUnlock({ payload }) {
 try 
 {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  const body = {};
   const response = yield call(
    api.post,
     `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/unlockUser/${payload.username}`,
     body,
     {
      headers: {
        "Content-Type": "application/json",
        userid: tGUID,
        id_token: "Bearer " + accessToken
      }
     }
   );
   if (response.status === 401) {
    yield put(actions.signOutAction()); 
    return; 
  }

   if (response && (response.status === 204 || response.status === 200)){
     yield put(RESChgSta(response));
   } 
   else
   {
     
     yield put(FailChgSta());
   }
 } catch (error) {
   
   yield put(FailChgSta());
 }
}
function* fetchImgCus({ payload }) {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try 
  {
     const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/customers/${payload.customerUuid}?fields=${encodeURIComponent("detail{avatar{url},profilePic{url},email,firstName,firstNameTh}")}`, 
     { 
       headers: {
         "Content-Type": "application/json",
         userid: tGUID,
          id_token: "Bearer " + accessToken
       }
     });
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    if (response) 
    {
      yield put(RESImgCus(response.data));
    } 
    else
    {
      
      yield put(FailImgCus());
    }
  } catch (error) {
    
    yield put(FailImgCus());
  }
}
function* fetchSta({ payload }) {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try 
  { 
     const url = `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customerAccountStatus/${payload.username}`;
     const response = yield call(
      api.get,
      url,
      { 
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      });
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    if (response && response.data) 
    {
      yield put(RESSta(response.data));
    } 
    else
    {
      
      yield put(FailSta());
    }
  } catch (error) {
    
    yield put(FailSta());
  }
}
function* fetchDelUsrAct({ payload }) {
  try 
  {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { UUID,history} = payload;
    const request = yield fetch(`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/userAccountDel`, 
   { 
      method: "POST",  
      headers:
       {
        "Content-Type": "application/json",
        userid: tGUID,
        id_token: "Bearer " + accessToken
      },
      body: JSON.stringify({
        uuid: UUID
      })    
    });
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
   
    const response = yield request.json();
    
    if (response && response.Code===200) 
    {
      yield put(RESDelUsrAct(response));
      history.push(`/UsrAct`); 
    } 
    else
    {
      
      yield put(RESDelUsrAct(response));
      yield put(FailDelUsrAct());
    }
  } catch (error) {
    
    yield put(FailDelUsrAct());
  }
}
export function* usrActDetailSaga() {
  yield all([
    takeLatest(REQTab2_RegisDeviceList, fetchTab2_RegisDeviceListWorker),
    takeLatest(REQImgCus, fetchImgCus) ,
    takeLatest(REQChgSta, CHGxUnlock),   
    takeLatest(REQSta, fetchSta), 
    takeLatest(REQDelUsrAct, fetchDelUsrAct)
  ]);
}
