import { put, call, all, takeLatest, select } from "redux-saga/effects";
import {
  REQNDIDLogDetail,
  RESNDIDLogDetail,
  FailNDIDLogDetail,
} from "./actions";
import * as actions from "../../Authentication/redux/actions";


function* fetchNDIDLogListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { identification_no} = payload;
    let tIdCard= identification_no===null?"":identification_no;
    
    const request = yield fetch(`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/ndidLogDetail`, 
    { 
      method: "POST",  
      headers:
       {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
      },
      body: JSON.stringify({
        idnumberSrch: tIdCard
      })
       
    });
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
   
    const response = yield request.json();
     
    if (response) 
    {
     yield put(RESNDIDLogDetail(response));
    } 
    else
     {
      
      yield put(RESNDIDLogDetail());
    }

  } catch (error) {
    
    yield put(FailNDIDLogDetail());
  }
}
export function* regisLogDetailSaga() {
  yield all([
    takeLatest(REQNDIDLogDetail, fetchNDIDLogListWorker)
  ]);
}
