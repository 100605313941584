import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  // -----FundCondition---
  REQFundRecCodiList,
  RESFundRecCodiList,
  FailFundRecCodiList,
} from "./actions";
import * as actions from "../../Authentication/redux/actions";


// --------------FundRecommend Condition---------------------------------
function* fetchFundRecCodiListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    const response = yield call(
      api.get,`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds/recommend_by_condition`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    if (response && response.data && response.data.data) {
      yield put(RESFundRecCodiList(response.data.data));
    } else {
      
      yield put(FailFundRecCodiList());
    }

  } catch (error) {
    
    yield put(FailFundRecCodiList());
  }
}



export function* fundRecCodiListSaga() {
  yield all([
    // ------FundRecommend Condition------
    takeLatest(REQFundRecCodiList, fetchFundRecCodiListWorker)
  ]);

  
}
