import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  requestClientsListAction,
  receiveClientsListAction,
  failClientsListAction,
  requestDisapproveClient,
  receiveDisapproveClient,
  failDisapproveClient,
  getApplicants,
  setApplicants,
  failApplicants
} from "./actions";
import { selectedObjectUuid } from "../../../components/BreadCrumbs/redux/actions";
import { setCurrentCustomer } from "../../ClientDetail/redux/actions";
import * as actions from "../../Authentication/redux/actions";


function* fetchClientsList({ payload }) {
  try {
    const {
      limit,
      offset,
      searchQuery = "",
      sortDirection,
      sortByDocStatus,
      sortByAccStatus,
      sortByConvertedUser = ""
    } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    
    const response = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customers?fields=creUpdatedAt,documentStatusCode,fromEasyinvest,enhancedKyc,firstNameEn,lastNameEn,firstNameTh,lastNameTh,id,kycSuccess,mobile,email,reviewAt,reviewAtOnSqlSrv,riskLevel,statusCode,submissionAt,unitholderId,uuid&limit=${limit}&offset=${offset}&sortKey=id&sortDir=${sortDirection}${searchQuery}${sortByAccStatus},${sortByDocStatus}${sortByConvertedUser}`,
      {
        method: "GET",  
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    
    if (response) {
      yield put(receiveClientsListAction(response.data));
    } else {
      yield put(failClientsListAction());
    }
  } catch (error) {
    
    yield put(failClientsListAction());
  }
}

function* disapproveClient({ payload }) {
  // Takes an existing Approved client, and resets them to Pending status
  try {
    const { customerUuid, unitholderid, firstName, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const body = {
      statusCode: "1"
    };
    const response = yield call(
      api.patch,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customers/${customerUuid}/status`,
      {
        headers: {
          "Content-Type": `application/json`,
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body:JSON.stringify(body),
        config
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  

    if (response && response.data && response.data.data) {
      yield put(
        setCurrentCustomer({
          uuid: customerUuid,
          unitholderid: unitholderid
        })
      );
      yield put(selectedObjectUuid(firstName));
      yield put(receiveDisapproveClient());
      history.push(`/clients/${customerUuid}`);
    } else {
      yield put(failDisapproveClient());
    }
  } catch (error) {
    
    yield put(failDisapproveClient());
  }
}

function* getApplicantSaga(action) {
  try {
    const { payload } = action;
    const { email = "", limit } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.get,
      email
        ? `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/getUsers?limit=${limit}&offset=${0}&email=${encodeURIComponent(
            email.trim()
          )}`
        : `${process.env.REACT_APP_API_URL}/sdkservice/listUsers`,
      {
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    if (response && response.data) {
      yield put(
        setApplicants({
          applicants: response.data
        })
      );
    } else {
      yield put(failApplicants());
    }
  } catch (err) {
    yield put(failApplicants());
  }
}

export function* clientsWorkerSaga() {
  yield all([
    takeLatest(requestClientsListAction, fetchClientsList),
    takeLatest(requestDisapproveClient, disapproveClient),
    takeLatest(getApplicants, getApplicantSaga)
  ]);
}
