import { put, call, all, takeLatest, select } from "redux-saga/effects";
import { OktaAuth } from "@okta/okta-auth-js";
import { requestDropDownData, stopLoading } from "../../../globalRedux/actions";
import { signIn as signInConstant } from "../../../globalRedux/constants/loader";
import { hideLoader, showLoader } from "../../../store/actions/loader";
import { aConfigUrl } from "../../../utils/ConfigUrl";
import * as api from "../../../utils/api";
import * as actions from "./actions";

const getToken = state => state.auth.accessToken;

function* signIn({ payload }) {
  try {
    yield put(showLoader(signInConstant));
    let API_URL = aConfigUrl().API_URL;
    let authClient = new OktaAuth(aConfigUrl());

    const authData = yield authClient.signInWithCredentials({
      username: payload.email,
      password: payload.password
    });

    const accessTokenData = yield authClient.token.getWithoutPrompt({
      responseType: "id_token",
      scopes: ["openid","email","offline_access"],
      sessionToken: authData.sessionToken
    });

    const oktaAccessToken = accessTokenData.tokens.accessToken.accessToken;
    const userClaims = yield authClient.token.getUserInfo(accessTokenData.tokens.accessToken, accessTokenData.tokens.idToken);

    const oNeedAuth = {
      Email: payload.email,
      Token: oktaAccessToken,
      GUID: userClaims.customerId,
      OKTAExpireUTC: new Date(Number(accessTokenData.tokens.accessToken.expiresAt) * 1000),
      OKTAToken: oktaAccessToken,
      b2cAccessToken: oktaAccessToken,
      b2cUserId: userClaims.customerId
    };
    console.log("accessTokenData: ", accessTokenData)
    console.log("Tokens", accessTokenData.tokens)
    console.log("oNeedAuth:",oNeedAuth);
    localStorage.setItem("NeedAuth", JSON.stringify(oNeedAuth));

    yield put(actions.userSignedIn({
      id: oktaAccessToken,
      ttl: authData.expiresAt,
      created: authData.expiresAt,
      userId: authData.user,
      accessToken: oktaAccessToken,
      GUID: userClaims.customerId
    }));
    yield put(stopLoading());
    yield call(setAccessTokenToHeader);
    yield put(requestDropDownData());
  } catch (error) {
    
    yield put(actions.RESOkta(error));
    yield put(actions.signInRequestFailedAction());
  } finally {
    yield put(hideLoader(signInConstant));
  }
}

function* signUp({ payload }) {
  try {
    const response = yield call(api.post, 'SIGN_UP_ENDPOINT', payload);
    yield put(actions.userSignedIn(response.data));
    yield call(setAccessTokenToHeader);
  } catch (error) {
    
    yield put(actions.signUpRequestFailedAction());
  }
}

function* signOut() {
  localStorage.removeItem("NeedAuth");

  yield put(actions.userSignedOut());
  yield call(api.resetAuthHeader);
}

function* refreshToken() {
  let authClient = new OktaAuth(aConfigUrl());
  
  try {
    const sessionExists = yield call([authClient.session, 'exists']);
    
    if (!sessionExists) {
      console.error("No active session exists. Redirecting to login.");
      yield put(actions.userSignedOut()); 
      return; 
    }

    const newToken = yield call([authClient.token, 'renewTokens']);
    const oktaAccessTokenRefreshed = newToken.accessToken.accessToken;
    const userClaims = yield call([authClient.token, 'getUserInfo'], newToken.accessToken, newToken.idToken);
    const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));

    const oNeedAuthRefresh = {
      Email: oNeedAuth.Email,
      Token: oktaAccessTokenRefreshed,
      GUID: userClaims.customerId,
      OKTAExpireUTC: new Date(Number(newToken.accessToken.expiresAt) * 1000),
      OKTAToken: oktaAccessTokenRefreshed,
      b2cAccessToken: oktaAccessTokenRefreshed,
      b2cUserId: userClaims.customerId
    };

    localStorage.setItem("NeedAuth", JSON.stringify(oNeedAuthRefresh));
    console.log("New Access Token: ", oNeedAuthRefresh);

    yield put(actions.userSignedIn({
      id: oktaAccessTokenRefreshed,
      ttl: oNeedAuthRefresh.OKTAExpireUTC,
      created: oNeedAuthRefresh.OKTAExpireUTC,
      userId: userClaims.customerId,
      accessToken: oktaAccessTokenRefreshed,
      GUID: userClaims.customerId
    }));

    yield call(setAccessTokenToHeader);

  } catch (error) {
    console.error("Error renewing tokens: ", error);
    yield put(actions.userSignedOut()); 
  }
}


function* setAccessTokenToHeader() {
  const token = yield select(getToken);
  yield call(api.setAuthHeader, token);
  return token;
}

export default function* authSagas() {
  yield all([
    takeLatest(actions.signInAction, signIn),
    takeLatest(actions.signUpAction, signUp),
    takeLatest(actions.signOutAction, signOut),
    takeLatest(actions.refreshToken, refreshToken)
  ]);
}
