import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  REQFundCodeList,
  RESFundCodeList,
  FailFundCodeList,
  REQTagDetailCrt,
  RESTagDetailCrt,
  FailTagDetailCrt,
  REQTagDetailEdt,
  RESTagDetailEdt,
  FailTagDetailEdt
} from "./actions";
import * as actions from "../../Authentication/redux/actions";

function* fetchFundCodeListWorker() {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const request = yield fetch(
      // direct
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds`,
      {
        method: "GET",  
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    const response = yield request.json();
    
    if (response && response.data) {
      yield put(RESFundCodeList(response));
    } else {
            yield put(FailFundCodeList());
    }
  } catch (error) {
    
    yield put(FailFundCodeList());
  }
}

function* INSxTagDetail({ payload }) {
  try {
    const { formData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = formData;
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/tag/create`,
      body,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    if (response && response.data && response.status === 200) {
      yield put(RESTagDetailCrt(response));
      history.goBack();
    } else {
      yield put(FailTagDetailCrt());
    }
  } catch (error) {
    yield put(FailTagDetailCrt());
  }
}
function* EDTxTagDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/tag/edit`,
      body,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }
    if (response && response.data && response.status === 200) {
      yield put(RESTagDetailEdt(response));
      history.goBack();
    } else {
      yield put(FailTagDetailEdt());
    }
  } catch (error) {
    
    yield put(FailTagDetailEdt());
  }
}
export function* tagDetailSaga() {
  yield all([
    takeLatest(REQFundCodeList, fetchFundCodeListWorker),
    takeLatest(REQTagDetailCrt, INSxTagDetail),
    takeLatest(REQTagDetailEdt, EDTxTagDetail)
  ]);
}
