import { put, call, all, takeLatest, select } from "redux-saga/effects";
import moment from "moment";
import { REQActiList, RESActiList, FailRegisActiList } from "./actions";
import * as actions from "../../Authentication/redux/actions";

function* fetchActiListWorker({ payload }) {
  console.log("fetchActiListWorker:",payload);
  try {
    const { page , tStartD, tEndD, tSrch, tOpSrch, tUserType } = payload;
    const tStartD1 = moment(tStartD).format("YYYY-MM-DD");
    let tEndD1 = moment(tEndD).format("YYYY-MM-DD");
    tEndD1 = tEndD1 + " 23:59";
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let tMobile = "";
    let tEmail = "";
    let tCustomerID = "";
    let tUsrName = "";
    switch (tOpSrch) {
      case "E-Mail":
        tEmail = tSrch;
        break;
      case "Customer ID":
        tCustomerID = tSrch;
        break;
      case "Username":
        tUsrName = tSrch;
        break;
      default:
        break;
    }

    console.log("fetchActiListWorker start:"); 
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/activityLog/search?page=${page || 1}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          startDate: tStartD1,
          endDate: tEndD1,
          email: tEmail,
          mobile: tMobile,
          uuid: tCustomerID,
          username: tUsrName,
          userType: tUserType
        })
      }
    );
    if (request.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }

    const response = yield request.json();
    if (response && response.data) {
      yield put(RESActiList(response.data));
    } else {
      yield put(FailRegisActiList());
    }
  } catch (error) {
    console.error("fetchActiListWorker error:",error);
    yield put(FailRegisActiList());
  }
}

export function* actiListSaga() {
  yield all([takeLatest(REQActiList, fetchActiListWorker)]);
}
