import React, { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import {
  setFullImgUrl,
  requestUntHldDetailDocuments,
  requestUntHldDetailDocumentApprove,
  requestUntHldDetailDocumentReject,
  requestUntHldDetailDocumentUndo,
  FETCH_REGISTERED_IMAGE_REQUEST,
  FETCH_COUNTERSERVICE_IMAGE_REQUEST,
  FETCH_UPLOAD_FILE_TO_S3_REQUEST,
  FETCH_SAVE_TO_DB_REQUEST,
  savingtodb
} from "./redux/actions";
import Images from "../../utils/styles/images";
import "./style.scss";
import axios from 'axios';
import Loader from "../../components/Loader";
/*
    Document Status Codes map to the following
    PENDING = 1,
    APPROVED = 2,
    REJECTED = 3,
    IN_REVIEW = 4,
*/


export const documentTypeNameMapping = () => {
  return {
    1: "IDFront.jpeg",
    2: "IDBack.jpeg",
    3: "AVATAR",
    4: "Bankbook.jpeg",
    5: "HouseholdBill.jpeg",
    6: "ACKNOWLEDGEMENT_SIGNATURE",
    7: "PassportFront.jpeg",
    9: "ndid.jpeg",
    10: "ID card_7-11.jpeg"
  };
};

function ClientDocuments(props) {
  const {
    requestUntHldDetailDocuments,
    requestingClientDocuments,
    documentDetails,
    requestDocumentApprove,
    requestDocumentReject,
    requestDocumentUndo,
    requestingDocumentApproval,
    requestingDocumentRejection,
    requestingDocumentUndo,
    clientDetail,
    customerChannel,
    avatar,
    FETCH_REGISTERED_IMAGE_REQUEST,
    registeredImage,
    regisImageDate,
    FETCH_COUNTERSERVICE_IMAGE_REQUEST,
    counterCard,
    counterIdentify,
    counterDate,
    FETCH_UPLOAD_FILE_TO_S3_REQUEST,
    s3posturl,
    s3formdata,
    FETCH_SAVE_TO_DB_REQUEST,
    savingtodb
  } = props;
  const [currentDocument, setCurrentDocument] = useState(0);
  const [zoomDocument, setZoomDocument] = useState(false);
  const [currentScrollYPosition, setCurrentScrollYPosition] = useState(0);
  const [rotateLevel, setRotateLevel] = useState(0);
  
  console.log("docdetails", documentDetails)
  const getNdidImage = () => {
    let tImg=null;
    let RegisURL = "";
    const typeCode9Document = documentDetails.find(doc => doc.typeCode === "9");
  
    if (registeredImage && typeCode9Document === undefined) {
      tImg=registeredImage;
      RegisURL = "data:image/png;base64," + tImg
    }
    return RegisURL
    }
  const imageURL = getNdidImage();
  
  
  const tempFile = useMemo(() => {
    if (imageURL.startsWith("data:image/")) {
      const arr = imageURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      const u8arr = new Uint8Array(bstr.length);
      for (let i = 0; i < bstr.length; i++) {
        u8arr[i] = bstr.charCodeAt(i);
      }
      return new Blob([u8arr], { type: mime });
    }
    return null;
  }, [imageURL]);
  
  

  
  // const getLastModified = () => {
  //   let lastModified = regisImageDate
  //   return lastModified
  // }
  
  useEffect(() => {
    FETCH_REGISTERED_IMAGE_REQUEST();
    // FETCH_COUNTERSERVICE_IMAGE_REQUEST();
      if (tempFile) {
        uploadFile('front', tempFile); 
      }
    requestUntHldDetailDocuments(avatar);
  }, []);
  console.log("requesting", requestingClientDocuments)
  useScrollPosition(({ currPos }) => {
    // Get the height difference after a user scrolls
     setCurrentScrollYPosition(currPos.y);
  });

  const rotateValue = useMemo(() => {
    return rotateLevel * 90;
  }, [rotateLevel]);

  const setRotateLevelLeft = () => {
    if (rotateLevel === 0) {
      setRotateLevel(3);
    } else {
      setRotateLevel(rotateLevel - 1);
    }
  }

  const setRotateLevelRight = () => {
    if (rotateLevel === 3) {
      setRotateLevel(0);
    } else {
      setRotateLevel(rotateLevel + 1);
    }
  }
  const SETxDocType9 =(pocustomerChannel)=>{
      let tType9="";
      switch(pocustomerChannel)
      {
        case"NDID":
        tType9="ndid photo ";
        break;
        case"COUNTER_SERVICE":
        tType9="counter service photo";
        break;
        default:
        tType9="Selfie With ID";
      }
      return tType9;
    };
  
  
    const uploadFile = async (position, file) => {
      try {
        FETCH_UPLOAD_FILE_TO_S3_REQUEST()
        const formData = new FormData();
        s3formdata.forEach(([key, value]) => {
          formData.append(key, value);
        });
    
        formData.append('file', file, `identification-${position}.jpg`);
        
        const response = await fetch(s3posturl, {
          method: 'POST',
          body: formData,
        });
        console.log('response', response)
        if (!response.ok) {
          throw new Error('Upload failed');
        }
    
        console.log('File uploaded successfully');
        FETCH_SAVE_TO_DB_REQUEST();
        
        setTimeout(() => {
          requestUntHldDetailDocuments(avatar);
        }, 8000);
        } catch (error) {
        console.error('Upload error:', error);
      }
    };
    
  

  let documentNameMapping = {
    1: "Thai ID",
    2: "Thai ID",
    3: "Profile Picture",
    4: "Bankbook",
    5: "ThaiHousehold Registration / Credit Card StatementID",
    6: "Signature",
    7: "Passport",
    // 9: "Selfie With ID",
    9: SETxDocType9(customerChannel),
    10: "ID card_7-11"
  };

  // function processDocumentDetails(documentDetails) {
  //   if (documentDetails) {
  //     const typeCode9Document = documentDetails.find(doc => doc.typeCode === "9");
  
  //   if (!typeCode9Document) {
  //     const newDocument = {
  //       typeCode: "9",
  //       lastModifiedAt: getLastModified(),
  //       url: getNdidImage(),
  //     };
  
  //     return [...documentDetails, newDocument];
  //   }
  //   return documentDetails;
  //   }
    
  
  //   return documentDetails;
  // }

  const documentStatus = documentDetails != null ?
    (documentDetails[currentDocument] &&
    documentDetails[currentDocument].statusCode
      ? documentDetails[currentDocument].statusCode
      : null) : null;

  const documentImage = documentDetails != null ?
    (documentDetails[currentDocument] && documentDetails[currentDocument].url
      ? documentDetails[currentDocument].url
      : null) : null;

  const documentTypeCode = documentDetails != null ?
    (documentDetails[currentDocument] &&
    documentDetails[currentDocument].typeCode
      ? documentDetails[currentDocument].typeCode
      : null) : null;
  const documentTypeName = documentTypeCode
    ? documentNameMapping[documentTypeCode]
    : "";
  const documentFilename = documentTypeCode
    ? documentTypeNameMapping()[documentTypeCode]
    : "Default.jpeg";
  const documentDate = documentDetails != null ?
    (documentDetails[currentDocument] &&
    documentDetails[currentDocument].lastModifiedAt
      ? moment(documentDetails[currentDocument].lastModifiedAt).format(
          "DD/MM/YYYY"
        )
      : null) : null;
  

  const ndidDocument = documentDetails;
  const ndidImage = documentDetails != null ? documentDetails.filter((data) => data.typeCode === 9).map((data) => {return data.url;}) : "";
  const documentNdidImage = ndidImage;
  const ndidDocumentTypeCode = documentDetails != null ? documentDetails.filter((data) => data.typeCode === 9).map((data) => {return data.typeCode;}) : "";
  const ndidDocumentTypeName = ndidDocumentTypeCode ? documentNameMapping[ndidDocumentTypeCode] : "";
  const ndidDocumentFileName = ndidDocumentTypeCode ? documentTypeNameMapping()[ndidDocumentTypeCode] : "Default.jpeg";

  const isUserCanApprove = clientDetail 
  ? clientDetail.canApprove === true ? true : false 
  : false;
  
  const handleRejectPressed = () => {
    console.log("handleRejectPressed requestDocumentReject >>");
    requestDocumentReject({
      documentID: documentDetails != null ? documentDetails[currentDocument].uuid : "",
      avatar: avatar
    });
  };

  const handleApprovePressed = () => {
    console.log("handleApprovePressed requestDocumentApprove >>");
    requestDocumentApprove({
      documentID: documentDetails[currentDocument].uuid,
      avatar: avatar
    });
  };
  

  const handleUndoPressed = () => {
    if(clientDetail.statusCode !== "2"){
      console.log("handleUndoPressed > start");
      requestDocumentUndo({ documentID: documentDetails[currentDocument].uuid,
        avatar: avatar
      });
    }else{
      console.log("handleUndoPressed > no action");
    }
    
  };

  const handleMagicDocumentFormScroll = () => {
    // This basically allows the document form to float into whatever empty space up top when user scrolls down
    if (currentScrollYPosition < 0) {
      if (currentScrollYPosition < -249) {
        return 100 - 250;
      } else {
        return 100 + currentScrollYPosition;
      }
    } else {
      return 100;
    }
  };

  const handleImageClick = () => {
    localStorage.setItem('fullImgUrl', documentImage);
    window.open(`/FullDocImage?imgUrl=${encodeURIComponent(documentImage)}&rotate=${rotateLevel}`);
};
  console.log("customerchannel", customerChannel)
  return (
  
    customerChannel === "NDID" && customerChannel === "COUNTER_SERVICE" ? (
    <div
      className="client-detail-profile-document-floater"
      style={{ marginTop: handleMagicDocumentFormScroll() }}
    >
      {requestingDocumentApproval ||
      requestingDocumentRejection ||
      requestingDocumentUndo ||
      savingtodb ||
      requestingClientDocuments ? (
        <div className="document-circular-loader">
          <CircularProgress className="document-status-progress" />
          {requestingDocumentApproval ? (
            <p className="document-status-text-progress">
              Requesting document approval...
            </p>
          ) : null}
          {requestingDocumentRejection ? (
            <p className="document-status-text-progress">
              Requesting document rejection...
            </p>
          ) : null}
          {requestingDocumentUndo ? (
            <p className="document-status-text-progress">
              Requesting document status reset...
            </p>
          ) : null}
          {
              requestingClientDocuments ? (
              <p className="document-status-text-progress">
                Refreshing document list...
              </p>
            ) : null
          }
          {
            savingtodb ? (
              <p className="document-status-text-progress">
                Refreshing document list...
              </p>
            ) : null
          }
        </div>
      ) : null}

      <p className="client-detail-profile-document-header">DOCUMENT</p>
      <div className="client-detail-profile-document-container">
        <img
          src={Images.documents.zoom}
          alt="magnifer-icon"
          onClick={() => setZoomDocument(!zoomDocument)}
          className="client-detail-document-zoom-button"
        />
        <div className="client-detail-profile-document-magnifier">
          {documentImage ? (
            <img
              src={documentNdidImage}
              alt="document-NDID"
              className={
                zoomDocument
                  ? "client-detail-document-image-zoomed"
                  : "client-detail-document-image"
              }
            />
          ) : null}
        </div>
        <div className="client-detail-profile-document-switcher">
          <img
            src={Images.documents.leftArrow}
            alt="left-arrow-icon"
            className={
              "client-detail-documents-left-arrow-disabled"
            }
          />
          <div className="client-detail-image-switcher-container">
            {ndidDocument.filter((document) => document.typeCode === 4).map((document, index) => (
              <img
                onClick={() => setCurrentDocument(index)}
                key={`mini-document-image-${index}`}
                src={document.url}
                alt={document.typeCode}
                className={
                  index === currentDocument
                    ? "client-detail-documents-mini"
                    : "client-detail-documents-mini-unselected"
                }
              />
            ))}
          </div>
          <img
            src={Images.documents.rightArrow}
            alt="right-arrow-icon"
            className={
              "client-detail-documents-right-arrow-disabled"
            }
          />
        </div>
      </div>
      <div className="client-detail-profile-document-details">
        <div className="client-detail-profile-document-details-headers">
          <p className="client-detail-profile-document-details-headers-doctype">
            DOC. TYPE
          </p>
          <p className="client-detail-profile-document-details-headers-filename">
            FILE NAME
          </p>
          <p className="client-detail-profile-document-details-headers-date">
            DATE
          </p>
        </div>
        <div className="client-detail-profile-document-details-values">
          <p className="client-detail-profile-document-details-values-doctype">
            {ndidDocumentTypeName}
          </p>
          <p className="client-detail-profile-document-details-values-filename">
            {ndidDocumentFileName}
          </p>
          <p className="client-detail-profile-document-details-values-date">
            {documentDate}
          </p>
        </div>
      </div>
      <div className="client-detail-profile-document-buttons-container">
        {documentStatus === "1" ? (
          <Fragment>
            <img
              src={Images.statusIcon.pending}
              alt="pending-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-pending">Pending</p>
          </Fragment>
        ) : null}
        {documentStatus === "2" ? (
          <Fragment>
            <img
              src={Images.statusIcon.approved}
              alt="approved-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-approved">Approved</p>
          </Fragment>
        ) : null}
        {documentStatus === "3" ? (
          <Fragment>
            <img
              src={Images.statusIcon.rejected}
              alt="rejected-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-rejected">Rejected</p>
          </Fragment>
        ) : null}
        {documentStatus === "4" ? (
          <Fragment>
            <img
              src={Images.statusIcon.pending}
              alt="in-review-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-pending">In-Review</p>
          </Fragment>
        ) : null}

        {documentStatus === "2" || documentStatus === "3" ? (
          <img
            src={Images.documents.undo}
            alt="refresh-icon"
            onClick={() => handleUndoPressed()}
            className={clientDetail.statusCode === "2" ? "client-detail-profile-document-undo-disabled" : "client-detail-profile-document-undo"}
          />
        ) : (
          <div className="space-between" />
        )}
        <button
          className={
            documentStatus === "2" || documentStatus === "3"
              ? "client-detail-profile-document-reject-disabled"
              : "client-detail-profile-document-reject"
          }
          disabled={documentStatus === "2" || documentStatus === "3" || clientDetail.statusCode === "2"}
          onClick={() => handleRejectPressed()}
        >
          REJECT
        </button>
        <button
          className={
            documentStatus === "2" || documentStatus === "3" || clientDetail.statusCode === "3" ||  clientDetail.statusCode === "2" || !isUserCanApprove
              ? "client-detail-profile-document-approve-disabled"
              : "client-detail-profile-document-approve"
          }
          disabled={documentStatus === "2" || documentStatus === "3" || !isUserCanApprove}
          onClick={() => handleApprovePressed()}
        >
          APPROVE
        </button>
      </div>
    </div>
    ) : (
      <div
        className="client-detail-profile-document-floater"
        style={{ marginTop: handleMagicDocumentFormScroll() }}
      >
        {requestingDocumentApproval ||
        requestingDocumentRejection ||
        requestingDocumentUndo ||
        savingtodb ||
        requestingClientDocuments ? 
        (
          <div className="document-circular-loader">
            <CircularProgress className="document-status-progress" />
            {requestingDocumentApproval ? (
              <p className="document-status-text-progress">
                Requesting document approval...
              </p>
            ) : null}
            {requestingDocumentRejection ? (
              <p className="document-status-text-progress">
                Requesting document rejection...
              </p>
            ) : null}
            {requestingDocumentUndo ? (
              <p className="document-status-text-progress">
                Requesting document status reset...
              </p>
            ) : null}
            {
              requestingClientDocuments ? (
                <p className="document-status-text-progress">
                  Refreshing document list...
                </p>
              ) : null
            }
             {
            savingtodb ? (
              <p className="document-status-text-progress">
                Refreshing document list...
              </p>
            ) : null
          }
          </div>
        ) : null}

        <p className="client-detail-profile-document-header">DOCUMENT</p>
        <div className="client-detail-profile-document-container">
          <img
            src={Images.documents.zoom}
            alt="magnifer-icon"
            onClick={() => setZoomDocument(!zoomDocument)}
            className="client-detail-document-zoom-button"
          />
          <img
            src={Images.documents.rotateRight}
            alt="magnifer-icon"
            className="client-detail-document-rotate-right-button"
            onClick={() => setRotateLevelRight()}
          />
          <img
            src={Images.documents.rotateLeft}
            alt="magnifer-icon"
            className="client-detail-document-rotate-left-button"
            onClick={() => setRotateLevelLeft()}
          />
          <div className="client-detail-profile-document-magnifier" onClick={handleImageClick}>
            {documentImage ? (
              <img
                src={documentImage}
                className={
                  zoomDocument
                    ? "client-detail-document-image-zoomed"
                    : "client-detail-document-image"
                }
                style={{ 
                  transform: `rotate(${rotateValue}deg)`, 
                  zIndex: -1,
                }}
              />
            ) : null}
            {/* {registeredImage ? (
              <img
              src={getNdidImage()}
              className={
                zoomDocument
                  ? "client-detail-document-image-zoomed"
                  : "client-detail-document-image"
              }
              style={{ 
                transform: `rotate(${rotateValue}deg)`, 
                zIndex: -1,
              }}
              />
            )  : null
            } */}
          </div>
          <div className="client-detail-profile-document-switcher">
            <img
              src={Images.documents.leftArrow}
              alt="left-arrow-icon"
              className={
                currentDocument === 0
                  ? "client-detail-documents-left-arrow-disabled "
                  : "client-detail-documents-left-arrow"
              }
              onClick={() =>
                setCurrentDocument(
                  currentDocument - 1 >= 0 ? currentDocument - 1 : currentDocument
                )
              }
            />
            <div className="client-detail-image-switcher-container">
              {documentDetails != null ? documentDetails.map((document, index) => (
                <img
                  onClick={() => setCurrentDocument(index)}
                  key={`mini-document-image-${index}`}
                  src={document.url}
                  alt={document.typeCode == 1 ? "Thai ID" : document.typeCode == 4 ? "Bankbook " : document.typeCode == 9 ? "NDID Photo" : "" }
                  className={
                    index === currentDocument
                      ? "client-detail-documents-mini"
                      : "client-detail-documents-mini-unselected"
                  }
                />
              )) : ""}
            </div>
            <img
              src={Images.documents.rightArrow}
              alt="right-arrow-icon"
              className={
                documentDetails != null? (currentDocument === documentDetails.length - 1
                  ? "client-detail-documents-right-arrow-disabled"
                  : "client-detail-documents-right-arrow") : "client-detail-documents-right-arrow-disabled"
              }
              onClick={() =>
                setCurrentDocument(
                  documentDetails != null ? (currentDocument + 1 < documentDetails.length
                    ? currentDocument + 1
                    : currentDocument) : currentDocument
                )
              }
            />
          </div>
        </div>
        <div className="client-detail-profile-document-details">
          <div className="client-detail-profile-document-details-headers">
            <p className="client-detail-profile-document-details-headers-doctype">
              DOC. TYPE
            </p>
            <p className="client-detail-profile-document-details-headers-filename">
              FILE NAME
            </p>
            <p className="client-detail-profile-document-details-headers-date">
              DATE
            </p>
          </div>
          <div className="client-detail-profile-document-details-values">
            <p className="client-detail-profile-document-details-values-doctype">
              {documentTypeName}
            </p>
            <p className="client-detail-profile-document-details-values-filename">
              {documentFilename}
            </p>
            <p className="client-detail-profile-document-details-values-date">
              {documentDate}
            </p>
          </div>
        </div>
        <div className="client-detail-profile-document-buttons-container">
          {documentStatus === "1" ? (
            <Fragment>
              <img
                src={Images.statusIcon.pending}
                alt="pending-icon"
                className="client-detail-profile-status-icon"
              />
              <p className="client-detail-document-pending">Pending</p>
            </Fragment>
          ) : null}
          {documentStatus === "2" ? (
            <Fragment>
              <img
                src={Images.statusIcon.approved}
                alt="approved-icon"
                className="client-detail-profile-status-icon"
              />
              <p className="client-detail-document-approved">Approved</p>
            </Fragment>
          ) : null}
          {documentStatus === "3" ? (
            <Fragment>
              <img
                src={Images.statusIcon.rejected}
                alt="rejected-icon"
                className="client-detail-profile-status-icon"
              />
              <p className="client-detail-document-rejected">Rejected</p>
            </Fragment>
          ) : null}
          {documentStatus === "4" ? (
            <Fragment>
              <img
                src={Images.statusIcon.pending}
                alt="in-review-icon"
                className="client-detail-profile-status-icon"
              />
              <p className="client-detail-document-pending">In-Review</p>
            </Fragment>
          ) : null}

          {documentStatus === "2" || documentStatus === "3" ? (
            <img
              src={Images.documents.undo}
              alt="refresh-icon"
              onClick={() => handleUndoPressed()}
              className={clientDetail.statusCode === "2" ? "client-detail-profile-document-undo-disabled" : "client-detail-profile-document-undo"}
            />
          ) : (
            <div className="space-between" />
          )}
          <button
            className={
              documentStatus === "2" || documentStatus === "3"
                ? "client-detail-profile-document-reject-disabled"
                : "client-detail-profile-document-reject"
            }
            disabled={documentStatus === "2" || documentStatus === "3" || clientDetail.statusCode === "2"}
            onClick={() => handleRejectPressed()}
          >
            REJECT
          </button>
          <button
            className={
              documentStatus === "2" || documentStatus === "3" || clientDetail.statusCode === "3" ||  clientDetail.statusCode === "2" || !isUserCanApprove
                ? "client-detail-profile-document-approve-disabled"
                : "client-detail-profile-document-approve"
            }
            disabled={documentStatus === "2" || documentStatus === "3" || !isUserCanApprove}
            onClick={() => handleApprovePressed()}
          >
            APPROVE
          </button>
        </div>
        <div className="client-detail-profile-document-buttons-container">
          {/* <button 
          className="client-detail-profile-document-refresh"
          onClick={() => handleRefresh()}
          >
            REFRESH
          </button> */}
        </div>
      </div>
    )
  );
 
}

const withConnect = connect(
  state => ({
    ...state.untHldDetailReducer
  }),
  {
    setFullImgUrl,
    requestUntHldDetailDocuments,
    requestDocumentApprove: requestUntHldDetailDocumentApprove,
    requestDocumentReject: requestUntHldDetailDocumentReject,
    requestDocumentUndo: requestUntHldDetailDocumentUndo,
    FETCH_REGISTERED_IMAGE_REQUEST,
    FETCH_COUNTERSERVICE_IMAGE_REQUEST,
    FETCH_UPLOAD_FILE_TO_S3_REQUEST,
    FETCH_SAVE_TO_DB_REQUEST,
  }
);

export default compose(
  withConnect,
  withRouter
)(ClientDocuments);
