import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  requestTransactionsList,
  cleanupTransactionsList
} from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import DateInput from "./DateInput";
import Pagination from "./Pagination";
import Images from "../../utils/styles/images";
import "./style.scss";

function Transactions(props) {
  const {
    requestTransactionsList,
    requestingTransactionList,
    transactionList,
    cleanupTransactionsList
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pageNo, setPageNo] = useState("1");
  const [sortDir, setSortDir] = useState("DESC");
  const [sortingNo, setSortingNo] = useState("0");
  const [showFilterInstructions, setShowFilterInstructions] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // since pentacle is unable to return total number of records to allow us to calculate pages, they said to hardcode expected records to 100
  const totalTransactions = 1000;

  const getTransactions = () => {
    requestTransactionsList({
      startDate,
      endDate,
      pageNo,
      sortDir,
      sortingNo,
      searchQuery
    });
  };

  useEffect(() => {
    // every time sort direction changes, we call api again (also only allow it to run if dates are set)
    if (!showFilterInstructions) {
      getTransactions();
    }

    // on component unmount, do cleanup
    return () => {
      cleanupTransactionsList();
    };
  }, [sortDir, pageNo]);

  const doSearch = () => {
    setPageNo(1);
    setSearchValue("");
    setSearchQuery("");
    setSortDir("DESC");
    setSortingNo("0");
    !showFilterInstructions &&
      requestTransactionsList({
        startDate,
        endDate,
        pageNo: "1",
        sortDir: "DESC",
        sortingNo: "0",
        searchQuery
      });
  };

  const clearSearch = () => {
    setPageNo(1);
    setSearchValue("");
    setSearchQuery("");
    setSortDir("DESC");
    setSortingNo("0");
    !showFilterInstructions &&
      requestTransactionsList({
        startDate,
        endDate,
        pageNo: "1",
        sortDir: "DESC",
        sortingNo: "0",
        searchQuery: ""
      });
  };

  const handlePageChange = page => {
    setPageNo(page);
  };

// start test
const handleActionHeaderClick = cell => {
/*
sortingNo:
0 : TransDate
1 : EffectiveDate
2 : Fund_Code
3 : TransNo
4 : Status
*/
  if (cell === "ORDER DATE") {
    setSortingNo("0");
  } else if (cell === "EFFECTIVE DATE") {
    setSortingNo("1");
  } else if (cell === "FUND CODE") {
    setSortingNo("2");
  } else if (cell === "STATUS") {
    setSortingNo("4");
  }

  if (cell === "EFFECTIVE DATE" || cell === "FUND CODE" || cell === "STATUS" || cell === "ORDER DATE") {
    setSortDir(prevSortDir => (prevSortDir === "ASC" ? "DESC" : "ASC"));
  }
};
// end test

  return (
    <Fragment>
      {requestingTransactionList ? (
        <div className="transactions-list-progress">
          <CircularProgress />
          <p className="transactions-list-progress-text">
            {requestingTransactionList
              ? "Retrieving Transactions list..."
              : null}
          </p>
        </div>
      ) : null}
      <div className="transactions-search-header">
        <div className="transactions-search-container">
          <div className="transactions-search">
            <img
              src={Images.search.search}
              alt="search-icon"
              className="transactions-list-begin-search"
              onClick={() =>
                searchQuery.length > 0 && !showFilterInstructions && doSearch()
              }
            />
            <input
              placeholder="Search by Unit holder ID"
              className="transactions-search-bar"
              value={searchValue}
              onChange={event => {
                setSearchValue(event.target.value.trim());
                setSearchQuery("&unitholderId=" + event.target.value.trim());
              }}
              onKeyDown={event => {
                if (event.keyCode === 13 && !showFilterInstructions) {
                  setSearchQuery("&unitholderId=" + event.target.value.trim());
                  doSearch();
                }
              }}
            />
            {searchQuery && searchQuery.length > 0 ? (
              <img
                src={Images.closeButton.close}
                alt="cancel-search"
                className="transactions-details-cancel-search"
                onClick={() => clearSearch()}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="date-range-container">
        <p className="date-range-filter-date">FILTER DATE</p>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <p className="date-range-separator">-</p>
        <DatePicker
          selected={endDate}
          onChange={date => moment(date).isAfter(startDate) && setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <button
          onClick={() => {
            getTransactions();
            setShowFilterInstructions(false);
          }}
          className="date-range-filter"
          disabled={!startDate || !endDate}
        >
          FILTER
        </button>
      </div>
      <div className="transactions-list-container">
        {showFilterInstructions ? (
          <div className="filter-date-instructions">
            <img src={Images.transactions.filter} alt="filter-date" />
            <p className="Filter-date-to-view">
              Filter date to view transaction details
            </p>
            <p className="Select-the-date-rang">
              Select the date range using the date picker above to view
              transaction details.
            </p>
          </div>
        ) : (
          <Table
            data={transactionList}
            columns={columns}
            handleActionHeaderClick={handleActionHeaderClick}
            pageIndex={pageNo}
            pageSize={10}
          />
        )}
      </div>
      {!showFilterInstructions && totalTransactions > 0 ? (
        <Pagination
          handlePageChange={handlePageChange}
          total={totalTransactions}
          pageSize={10}
          defaultPage={1}
        />
      ) : null}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.transactionReducer
  }),
  {
    requestTransactionsList,
    cleanupTransactionsList
  }
);

export default compose(
  withConnect,
  withRouter
)(Transactions);
