import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  REQFundRecList,
  RESFundRecList,
  FailFundRecList,
  REQFundRecUpDoc,
  RESFundRecUpDoc,
  FailFundRecUpDoc
} from "./actions";
import * as actions from "../../Authentication/redux/actions";


function* fetchFundsListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    const response = yield call(
      api.get,
       `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds/recommend_by_fund_manager`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.signOutAction()); 
      return; 
    }  
   
    if (response && response.data) {
      yield put(RESFundRecList(response.data));
    } else {
      
      yield put(FailFundRecList());
    }

  } catch (error) {
    
    yield put(FailFundRecList());
  }
}
function* uploadDocumentsWorker({ payload }) {
  try {
    let responsePerformance = false;
    if (payload.performanceDocument.length > 0) {
      let performanceFormData = new FormData();
      performanceFormData.append("file", payload.performanceDocument[0]);

      responsePerformance = yield call(
        api.post,
        `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/performances/upload`,
        performanceFormData
      );
      if (responsePerformance.status === 401) {
        yield put(actions.signOutAction()); 
        return; 
      }  
    }

    let responseBenchmark = false;
    if (payload.benchmarkDocument.length > 0) {
      let benchmarkFormData = new FormData();
      benchmarkFormData.append("file", payload.benchmarkDocument[0]);

      responseBenchmark = yield call(
        api.post,
        `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/benchmarks/upload`,
        benchmarkFormData
      );
      if (responseBenchmark.status === 401) {
        yield put(actions.signOutAction()); 
        return; 
      }  
    }

    let responseDividend = false;
    if (payload.dividendDocument.length > 0) {
      let dividendFormData = new FormData();
      dividendFormData.append("file", payload.dividendDocument[0]);

      responseDividend = yield call(
        api.post,
        `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/dividends/upload`,
        dividendFormData
      );
      if (responseDividend.status === 401) {
        yield put(actions.signOutAction()); 
        return; 
      }  
    }

    if (responsePerformance || responseBenchmark || responseDividend) {
      yield put(RESFundRecUpDoc());
    } else {
      yield put(FailFundRecUpDoc());
    }
  } catch (error) {
    
    yield put(FailFundRecUpDoc());
  }
}



export function* fundRecListSaga() {
  yield all([
    takeLatest(REQFundRecList, fetchFundsListWorker),
    takeLatest(REQFundRecUpDoc, uploadDocumentsWorker)
  ]);

  
}
